$primary: #335b77;
$yellowish: #e4cf5a;
$pinkish: desaturate(lighten($primary, 50%), 30%);
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

//@import '../../node_modules/bootstrap/scss/bootstrap';

@import url("//fonts.googleapis.com/css2?family=Darker+Grotesque:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("//fonts.googleapis.com/css2?family=KoHo:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");


@mixin small {
  @media only screen and (min-width: $sm) {
      @content;
  }
}

$theme-colors: (
  primary: $primary,
  pinkish: $pinkish,
  yellowish: $yellowish,
);

body {
  background-color: #282c34;
  height: auto;
  width: auto;
  margin: 0;
}

figure {
  text-align: center;
  color: #ffffffd6;
  width: 100%;
  height: 100%;
  margin: 0px !important;
  padding: 1rem;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}

.store-figure {
  position: relative;
  display: flex !important;
  flex-direction: column;
}

.store-figure:hover {
  box-shadow: 0px 4px 20px tint-color($pinkish, 50%);
}

.fig-text {
  color: whitesmoke;
}

.ssImg {
  margin-top:30% !important;
}

.ss-prod-shipping {
  text-decoration:none;
  font-style:italic;
  text-shadow:rgba(0, 0, 0, 0.3) 0px 1px 1px;
  background-color:transparent;
  color:rgba(248, 221, 159, 0.84);
}

.ss-prod-out {
  text-decoration: none;
  font-style: italic;
  text-shadow: rgba(0, 0, 0, 0.3) 0px 1px 1px;
  background-color: transparent;
  color: rgba(230, 48, 48, 0.84);
}

#confetti-holder {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.container {
  padding-bottom: 20px;
}

.alert {
  position: sticky;
  top: 0;
  z-index: 1071;
}

.alert-merch {
  background-color: #c7d5da;
  color: #000000;
  box-shadow: 1px 1px 3px #808075;
  text-align: center;
  font-weight: bold;
  font-size: calc(8px + .5vmin);
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.alert-out-of-stock {
  text-align: center;
  font-weight: bold;
  font-size: calc(8px + .5vmin);
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* Button */
.sale-button {
  font-size: calc(10px + 2vmin)!important;
  color: #f25619;
}

/* Strong Tag */
.alert-sale-heading {
  color: #ffdb58;
  font-family: Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', 'sans serif';
  font-style: normal;
  text-align: center;
  font-size: calc(10px + 2vmin);
  text-shadow: rgba(0, 0, 0, 0.3) 0px 1px 1px;
}

/* Used for images in carousel */
.img-ratio {
  aspect-ratio: .95;
}

.alert-success-sale-col {
  display: inline-flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.alert-sale-info {
  color: #ffffff;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-style: italic;
  letter-spacing: 1cap;
  margin: inherit;
  text-align: center;
  font-size: calc(6px + 2vmin);
  text-shadow: rgba(0, 0, 0, 0.3) 0px 1px 1px;
}

.alert-success-sale {
  background-color: #85bcc4;
  z-index: 0;
  padding-top: 0.1rem ;
  padding-bottom: 0.1rem ;
}

.container-alert-sale {
    display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
}

.home-tour-heading {
  font-style: normal;
  font-family: "Lexend Exa", sans-serif;
  text-align: left;
  text-shadow: #3e3e3e 1px 1px 0px, #000000 2px 2px 17px;
  word-spacing: 13px;
}

.home-tour-info {
  font-style:italic;
  text-transform:capitalize;
  text-decoration:none;
  letter-spacing:-0.4px;
  color:#eadfb2;
}

.btn-remove {
  padding: .1rem .5rem;
  font-size: .8rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.btn-pinkish {
  background: #335b77;
  border-color: #26445a;
  color: whitesmoke;
}

.btn-pinkish:hover {
  background: #6594b5;
}

.product-link {
  align-self: center;
  margin-top: auto;
}

.store-title {
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.ss-img-out {
  opacity: 0.31 !important;
}

.store-title:hover {
  text-shadow: 1px 1px 5px tint-color($primary, 40%);
  text-decoration: underline solid black 2px;
}

.switch-wrapper {
  position: relative;

  & > div {
    position: absolute;

    & > .row {
      margin-bottom: 50px;
    }
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.order-header {
  color: #e0d3b5;
  font: revert;
}

.order-details
{
  color: black;
  font: revert;
}

.tour-h1 {
 color: #cdbb70;
 font-weight: 700;
 font-family: "KoHo", sans-serif;
 letter-spacing: 0.9px;
 word-spacing: 1.7px;
 column-count: 1;
 text-shadow: #626262 0px 1px 1px, RGB(24 24 24 / 22%) 4px 5px 1px, black 1px 0px 1px, black -1px 0px 1px;
 text-align: center;
 display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(25px + 2vmin);
}

.tour-h3 {
 text-transform:uppercase;
 text-align:center;
 line-height:1.2em;
 color:#e5f0ff;
 column-count:1;
 font-family:'Darker Grotesque', sans-serif;
 letter-spacing:0.7px;
 font-weight:500;
 word-spacing:0.5px;
 font-style: italic;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-size: calc(10px + 2vmin);
 padding-top: .2em;
}

.tour-h3-top {
 padding-top: 1.2em;
 text-transform:uppercase;
 text-align:center;
 line-height:1.2em;
 color:#e5f0ff;
 column-count:1;
 font-family:'Darker Grotesque', sans-serif;
 letter-spacing:0.7px;
 font-weight:500;
 word-spacing:0.5px;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-size: calc(10px + 2vmin);
 font-style: italic;
}

.bg-secondary {
 color: #fff086e0;
}

.heathered-dark-gray {
  color: #585858;
}

.heathered-forest-green {
  color: #7a8768;
}

.heathered-dark-navy {
  color: #393d8b;
}

.dark-navy {
  color: #02075d;
}

.white {
  color: white;
}

.maroon{
  color: maroon;
}

.grey{
  color: gray;
}

.teal {
  color: #008080;
}

.black-heather{
  color: #0b0b0b;
}

.forest{
  color: #223e25;
}

.asphalt{
color: #52514f;
} 

.kelly{
  color: #1a9462;
}

.dusty-blue
{
color: #85c8Bc;
}

.mustard
{
color: #eda027;
}

.product-size-active {
 background-color: #6b6b73 !important;
}

.product-size-active:hover {
  background-color: #6b6b73 !important;
 }

 .ui.active.button:active, .ui.button:active {
  background-color: transparent !important;
 }

 .ui.active.button:active
 {
   background-color: transparent !important;
   color:RGB(251 249 249);
 }

.ui.button:active {
  background-color: transparent  !important;
  color:RGB(251 249 249);
}

.ui.active.button:hover {
  background-color: transparent  !important;
}

 .ui.button:hover{
  background-color: transparent ;
  color:RGB(251 249 249);
 }

 .product-size {
  background: transparent !important;
 }

.product-size:hover {
  background-color: #878790 !important;
}

.ui.button{
  background: transparent;
  color: RGB(251 249 249);
}

.ui.button:focus {
  background-color: transparent;
  color:RGB(251 249 249);
}

.grow { 
  transition: all .2s ease-in-out;
  transform: scale(1.5); 
  color: transparent !important;
}

.grow:hover { 
  transform: scale(1.5); 
}

.grow:focus { 
  transform: scale(1.5); 
}

.grow:active {
  color: transparent !important;
}

footer {
  position: relative;
  width: 100%;
  bottom: 0;
}

.social-links{
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-items: baseline;
}


/* App CSS */

/* CSS file to be applied for the application */

/* Animations */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin 1 0.8s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg) scale(0.3, 0.3);
  }
  to {
    transform: rotate(360deg) scale(1, 1);
  }
}

/* Elements */
html{ 
  min-height: 100vh;
  overflow: auto;
}

body {
  background-color: #282c34;
  min-height: 100vh;
  overflow: auto;
  margin: 0;
}

div a {
  color: white;
}

div {
  position: relative;
}

.wrapper {
  min-height: 100%;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -50px;
}

a {
  color: #ffffffee !important;
  text-decoration: none;
}

a:hover {
  color: #b1b1b1ee !important;
}

a:active {
  box-shadow: 0px 4px 20px tint-color($pinkish, 50%);
}

.nav-focus {
  box-shadow: 0px 4px 20px tint-color($pinkish, 90%);
}

.dropdown-menu {
  background-color: transparent;
  border: none;
}

.dropdown-desktop {
  background-color: #343434f5 !important;
  margin: 0px 0px 0px 0px !important;
}

.dropdown {
  margin: -8px 0px 0px 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: rgba(255, 255, 255, 0.5);
}

.MuiGridListTileBar-titleWrap {
  color: #fff;
  font-variant: small-caps;
  font-style: italic;
  font-weight: bolder;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.navbar-header {
  float: left;
  padding: 15px;
  text-align: center;
  width: 100%;
}

.navbar-brand {
  float: none;
  font-size: 2.5rem;
  margin-right: 0 !important;
}

.nav-link {
  color: #f6f6f6f6;
}

.navbar-header {
  padding: 0px;
}

.inactive {
  color: #f6f6f6f1;
  text-decoration: none;
  white-space: nowrap;
}

.active {
  color: white;
  text-decoration: none;
}

.social-container {
  background: transparent;
}

.social{
  margin-left: 3%;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #ff0000 !important;
}

a.facebook {
  color: #4267b2 !important;
}

a.twitter {
  color: #00acee !important;
}

a.instagram {
  color: #c13584 !important;
}

a.spotify {
  color: #1db954 !important;
}

a.itunes {
  color: #ea4cc0 !important;
}

a.band {
  color: #629aa9 !important;
}

a.twitch {
  color: #6441a5 !important;
}

.center {
  margin: auto;
  width: fit-content;
  padding: 10%;
}

.flex-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.spotify-iframe-style {
  border: none;
  overflow: hidden;
}

.copy-right {
  color: #e6e6e6;
  font-style: oblique;
  font-variant: oldstyle-nums;
  font-size: x-small;
}

.header {
  font-size: calc(25px + 2vmin);
  display: inline-block;
  margin-right: 15rem;
  font-family: monospace;
  font-variant: small-caps;
  font-weight: bolder;
  letter-spacing: 0.06em;
  background: white;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: white;
  text-decoration: none !important;
}

.header:active {
  box-shadow: none;
}

.header:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  text-decoration: none;
}

.spotify-header{
  color: white;
  font-family: cursive;
}

#spotify-iframe-style a:link {
  color: white !important;
}

/* Id's */
#youTube {
  align-items: center;
}

#navBar {
  background-color: #343434f5;
}

#navComp {
  font-family: cursive;
  font-weight: bolder;
  font-size: calc(3px + 2vmin);
}

#collasible-nav-dropdown {
  color: #f6f6f6e0 !important;
}

#collasible-nav-dropdown:hover {
  color: #040507c7 !important;
}

.bandImg {
  float: left;
  width: 15vh;
}

#smallerImg {
  float: left;
  width: 450px;
  height: 465px;
  object-fit: scale-down;
  overflow: visible;
}

#gridBio {
  text-align: center;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 10% !important;
}

.prod-form {
  display: flex;
  align-items: stretch;
  justify-content: center;
  align-content: center;
}

.tour-img-smaller {
  max-width:92% !important;
  float:right !important;
}

.tour-img-bigger {
  float:left !important;
}

.email-modal-button {
    display: flex;
    align-content: stretch;
    align-items: center;
    flex-direction: column;
    color: white;
}

.img-fluid-home {
  max-width: 60%;
  height: auto;
}

.img-vinyl {
  padding-top: 25% !important;
}

.img-blur-edge{
  border-top-left-radius:0px;
  box-shadow:1px -2px 12px -6px #ffffff;
  border-top-left-radius:4%;
  border-top-right-radius:4%;
  border-bottom-left-radius:4%;
  border-bottom-right-radius:4%;
}

.tour-buttons{
  display: flex;
  justify-content: center;
  padding-top: 3%;
}

.tour-header-con{
  display: flex;
  justify-content: center;
  padding-top: .5%;
}

.button-link{
  padding-left: 2%;
}

.tour-header{
 color: cornsilk;
}

.phone-anchor {
  color: RGB(53, 93, 131) !important;
}